h1 {
    font-family: $font-title;
    font-weight: $font-weight-bold;
    @include toRem($font-size-h1);
}

h2 {
    font-family: $font-title;
    font-weight: $font-weight-bold;
    @include toRem($font-size-h2);
}

h3 {
    font-family: $font-title;
    font-weight: $font-weight-bold;
    @include toRem($font-size-h3);
}

h4 {
    font-family: $font-title;
    font-weight: $font-weight-medium;
    @include toRem($font-size-h4);
}

h5 {
    font-family: $font-title;
    font-weight: $font-weight-medium;
    @include toRem($font-size-h5);
}

h6 {
    font-family: $font-title;
    font-weight: $font-weight-medium;
    @include toRem($font-size-h6);
}


p {
    font-family: $font;
    font-weight: $font-weight-regular;
    @include toRem($font-size-body);
}

.subtitle {
    font-family: $font-title;
    font-weight: $font-weight-bold;
    @include toRem($font-size-subtitle);
}

label {
    font-family: $font;
    font-weight: $font-weight-semibold;
    @include toRem($font-size-label);
}

caption {
    font-family: $font;
    font-weight: $font-weight-regular;
    @include toRem($font-size-caption);
}