//COLORS
$black: #000;
$white: #fff;
$gainsboro: #DADADA;

//FONT-FAMILY
$font: "Muli", sans-serif;
$font-title: "Neue Haas Grotesk Display Pro", sans-serif;

//FONT-SIZES: they are used together with the mixin toRem to get value in rem unit.
$font-size-base: 20px;
$font-size-h1: 90px;
$font-size-h2: 68px;
$font-size-h3: 50px;
$font-size-h4: 38px;
$font-size-h5: 28px;
$font-size-h6: 22px;
$font-size-body: $font-size-base;
$font-size-body-alternate: 16px;
$font-size-subtitle: $font-size-base;
$font-size-label: $font-size-body-alternate;
$font-size-caption: 12px;

//FONT-WEIGHTS
$font-weight-thin: 100;
$font-weight-extralight: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;

//BREAKPOINTS
$breakpoints: (
    xs: 36rem,
    //576
    sm: 48rem,
    //768
    md: 62rem,
    //1024
    lg: 85rem,
    //1280
    xl: 96rem,
    //1560
    giga: 120rem,
);




