$height-video: 80vh;
$height-video-mobile: 55vh;
$margin-footer-top: 5vh;
$margin-footer-top-mobile: 12vh;

.video {
    height: $height-video;
    width: 100vw;
    object-fit: cover;
    display: block;

    @include breakpoint-down(md){
        height: $height-video-mobile;
    }
}

.footer {
    height: 100vh - $height-video - 2*$margin-footer-top;
    padding: $margin-footer-top 5vw;
    display: flex;
    flex-direction: column;
    gap: 1vh;

    @include breakpoint-up(md){
        flex-direction: row;
        justify-content: space-between;
        gap: unset;
        flex-wrap: wrap;
    }
    @include breakpoint-down(md){
        height: 100vh - $height-video-mobile - $margin-footer-top-mobile;
        padding: $margin-footer-top-mobile 5vw 0 5vw;
    }

    p {
        @include toRem($font-size-body-alternate);
        margin: 0.5vh;
    }

    .subtitle {
        margin-bottom: 1vh;
        width: 100%;
    }
}