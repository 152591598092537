/*** 
ToRem

Description:
    a function to calculate rem unit from pixels.

Required arguments:
    - $value: the value to be converted (px)
    
Returns:
    Font-size property with the value converted in rem unit.

***/

@mixin toRem($value){
    font-size: ($value/$font-size-base) + rem;
}